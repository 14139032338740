import { connect } from 'react-redux'
import Component from './component'
import { sIsMD } from '../../redux/modules/browser'
import { closeModal } from '../../redux/modules/lightningCheckout'

const WithData = connect(
  state => ({
    isMD: sIsMD(state),
    showModal: state.lightningCheckout.showModal,
    propertyId: state.lightningCheckout.propertyId,
    offerType: state.lightningCheckout.offerType,
  }),
  dispatch => ({ closeModal: () => dispatch(closeModal()) })
)(Component)

export default WithData
