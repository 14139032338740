import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import DetailedRequestForm from '../Forms/DetailedRequest'
import './styles.scss'

const LightningCheckout = ({
  showModal,
  closeModal,
  propertyId,
  offerType,
}) => {
  useEffect(() => {
    document.documentElement.classList[showModal ? 'add' : 'remove'](
      'no-scroll'
    )
  }, [showModal])

  if (!showModal) return <div />

  const closeButton = (
    <span onClick={closeModal} className="material-icons">
      close
    </span>
  )

  return (
    <div
      className={`dhsv_lightning_checkout`}
      onClick={e => {
        if (e.target === e.currentTarget) {
          closeModal()
        }
      }}
    >
      <div className="container">
        <DetailedRequestForm
          headerRightElement={closeButton}
          formVariant="single"
          propertyId={propertyId}
          offerType={offerType}
        />
      </div>
    </div>
  )
}

LightningCheckout.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  propertyId: PropTypes.number,
  offerType: PropTypes.string,
}

export default LightningCheckout
